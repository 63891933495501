<template>
  <b-container>
    <b-icon
      icon="arrow-clockwise"
      animation="spin"
      font-scale="4"
      v-if="isBussy"
    ></b-icon>
    <b-container v-else>
      <b-row class="mb-3">
        <b-col class="text-left">
          <label><b>Sede</b></label>
          <b-form-select
            v-model="placeSel"
            :options="placesOpt"
            placeholder="Seleccione una sede"
            @change="changePlace()"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-left">
          <label for="example-datepicker"><b>Fecha</b></label>
          <b-form-datepicker
            id="example-datepicker"
            class="mb-2"
            placeholder="Seleccione una fecha"
            @context="onContext"
          ></b-form-datepicker>
        </b-col>
        <b-col class="text-left">
          <label for="example-datepicker"><b>Horario</b></label>
          <b-form-select
            v-model="scheduleSel"
            :options="schedulesOpt"
            placeholder="Seleccione un Horario"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-button variant="outline-primary" @click="exportarDatos()"><b-icon icon="file-excel"></b-icon> Exportar Datos</b-button>
          <b-button class="ml-2" variant="outline-primary" @click="estadisticas()"><b-icon icon="graph-up"></b-icon> Estadísticas</b-button>
          <!-- <b-button class="ml-2" variant="outline-primary" @click="descargarLog()"><b-icon icon="graph-down"></b-icon> Log</b-button> -->
        </b-col>
      </b-row>
      <b-row>
        <!-- <pre>{{ log }}</pre> -->
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-table
            v-if="!isBussy"
            hover
            bordered
            :items="reservesByDateFiltered"
            :fields="fields"
            table-variant="primary"
            head-variant="dark"
            small
            style="font-size: 0.9rem"
            responsive
            sort-direction="asc"
            sort-by="idSchedule"
          >
            <template #cell(name)="data">
              <span>
                <span v-html="data.value"></span>
              </span>
            </template>
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(document)="data">
              <p class="text-left">{{ data.item.document }}</p>
            </template>
            <template #cell(name)="data">
              <p class="text-left">{{ data.item.name.toUpperCase() }}</p>
            </template>
            <!-- <template #cell(options)="data">
              <button @click="eliminarReserva(data.item.id)">Eliminar</button>
            </template> -->
          </b-table>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import xlsx from "xlsx";
export default {
  data() {
    return {
      dateSel: null,
      scheduleSel: null,
      schedulesOpt: [],
      fields: [
        {key: "index", label: '#'},
        {
          key: "idSchedule",
          thClass: "d-none",
          tdClass: "d-none",
          sortable: true,
        },
        { key: "schedule", label: "Horario" },
        { key: "document", label: "Documento" },
        { key: "name", label: "Nombre" },
        { key: "phone", label: "Teléfono" },
        { key: "eps", label: "Eps" },
        { key: "email", label: "Email" },
        // { key: "options", label: "Options" },
      ],
      isBussy: false,
      placesOpt: [],
      placeSel: null,
      isBussy: true,
      log: {}
    };
  },
  mounted() {
    if (!localStorage.getItem("emailLogged")) {
      this.$router.replace("/login");
    }
    this.setLayout("AdminLayout");
    this.getSchedulesByPlace(this.placeSel).then((rta) => {
      this.schedulesOpt.push(
        // {
        //   value: null,
        //   text: "Seleccione Horario",
        // },
        {
          value: null,
          text: "Todos los Horarios",
        }
      );
      this.schedules.forEach((element) => {
        this.schedulesOpt.push({
          value: element.schedule,
          text: element.schedule,
        });
      });
    });
    this.getPlacesAvailables();
  },
  computed: {
    ...mapState(["userLogged"]),
    ...mapState("schedules", ["schedules"]),
    ...mapState("reserves", ["reservesByDate"]),
    ...mapState("places", ["places"]),
    reservesByDateFiltered() {
      if (this.scheduleSel == null) {
        return this.reservesByDate.sort((a,b) => {
          if(a.document < b.document) {
            return -1
          }
          if(a.document > b.document){
            return 1
          }
          return 0
        });
      } else {
        return this.reservesByDate.filter(
          (item) => item.schedule == this.scheduleSel
        ).sort((a,b) => {
          if(a.document < b.document) {
            return -1
          }
          if(a.document > b.document){
            return 1
          }
          return 0
        });
      }
    },
  },
  methods: {
    ...mapActions(["setLayout"]),
    ...mapActions("reserves", [
      "getReservesByDate",
      "getReservesByDatePrincipal",
      "setPlaceSelected",
      "setIdReserve",
      "deleteReserve"
    ]),
    ...mapActions("schedules", [
      "getSchedules",
      "getSchedulesByPlace",
    ]),
    ...mapActions("places", ["getPlaces"]),
    ...mapActions("users", ["getLog"]),
    onContext(ctx) {
      this.dateSel = null;
      this.dateSel = ctx.selectedYMD;
      this.scheduleSel = null;
      if (this.dateSel) {
        this.getReserves();
        this.getSchedulesOpt();
      }
    },
    getReserves() {
      // if (this.placeSel || this.dateSel || this.scheduleSel) {
      this.setPlaceSelected({
        name: this.placeSel,
        id: this.getIdPlace(this.placeSel),
      });

      this.getReservesByDatePrincipal(this.dateSel);
    },
    async getPlacesAvailables() {
      await this.getPlaces();
      this.places.forEach((element) => {
        this.placesOpt.push({
          value: element.name,
          text: element.name + " => " + element.adress,
        });
      });
      this.isBussy = false;
    },
    getIdPlace(namePlace) {
      const place = this.places.filter((el) => {
        if (el.name == namePlace) {
          return el;
        }
      });
      return place[0].id;
    },
    async changePlace() {
      await this.getReserves();
      this.getSchedulesOpt();
    },
    getSchedulesOpt() {
      this.getSchedulesByPlace(this.placeSel).then((rta) => {
        this.schedulesOpt = [];
        this.schedulesOpt.push({
          value: null,
          text: "Todos los Horarios",
        });
        this.schedules.forEach((element) => {
          this.schedulesOpt.push({
            value: element.schedule,
            text: element.schedule,
          });
        });
      });
    },
    exportarDatos() {
      if (localStorage.getItem("emailLogged")) {
        this.$router.push("/admin/export-excel");
      }
    },
    estadisticas() {
      if (localStorage.getItem("emailLogged")) {
        this.$router.push("/admin/reports");
      }
    },
    async descargarLog(){
      let logs = await this.getLog();
      this.log = logs.filter(e => {
        return e.event == 'Reserve Created'
      }).map(el => {
        return {
          event: el.event,
          document: el.data.document,
          date: el.date
        }
      })
      let data = xlsx.utils.json_to_sheet(this.log);
      const workbook = xlsx.utils.book_new();
      const filename = `log_${moment().format("DDMMYYYY")}`;
      xlsx.utils.book_append_sheet(workbook, data, filename);
      xlsx.writeFile(workbook, `${filename}.xlsx`);
    },
    async eliminarReserva(id){
      await this.setIdReserve(id);
      await this.deleteReserve()
      this.changePlace()
    }
  },
};
</script>
<style lang="scss" scoped></style>
